import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { HttpService } from "src/app/services/api/http.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { LoadingService } from "src/app/services/loading.service";
import { State } from "src/app/shared/shared";
import { strToHexCharCode } from "src/app/utils";
import { environment } from "src/environments/environment";
import { constantValues } from "src/app/constants/constant";

@Component({
  selector: "app-audit-trail-history",
  templateUrl: "./audit-trail-history.component.html",
  styleUrls: ["./audit-trail-history.component.sass"],
})
export class AuditTrailHistoryComponent implements OnInit {
  @Input() auditTrailHistories: Array<any>;
  settings = constantValues;
  baseUrl = environment.gateway;
  constructor( private http: HttpService,
    private loadingService: LoadingService,
    private authService: AuthService
    ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  transition(h) {
    return Object.keys(h)[0];
  }
  async downloadFile(fileKey: string, fileName: string): Promise<void> {
    const state = environment.role;
    const access_token = `Bearer ${this.authService.passport.access_token}`;
    const role =
      (state === State.buyer && this.authService.passport.buyer?.RoleCode) ||
      (state === State.supplier &&
        this.authService.passport.supplierMember?.RoleCode);
    const downloadPath =
      state === State.buyer ? "buyer/file/download" : "supplier/file/download";
    const params = strToHexCharCode(
      `key=${fileKey}&Authorization=${access_token}&state=${state}&role=${role}`
    );
    const url = `${this.baseUrl}/${downloadPath}`;
    try {
      this.loadingService.openLoading(url);
      const data: BlobPart = await this.http.PostBlobPromise(
        url,
        { fileCode: params },
        "blob"
      );
      const blob = new Blob([data]);
      // for ie
      if ("msSaveOrOpenBlob" in navigator) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        link.setAttribute("href", URL.createObjectURL(blob));
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.closeLoading();
    }
  }
}
