import { Component, OnInit, HostListener } from "@angular/core";
import { privacyStatementState } from "src/app/interfaces/mapping";
import { SupplierService } from "src/app/services/supplier.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierPendingStatus } from "src/app/interfaces/mapping";

import { AcceptModalComponent } from "src/app/modules/buyer/views/accept-modal/accept-modal.component";
import { CommonService } from "src/app/services/common.service";
import { DialogService, ButtonCommands, DialogType } from "src/app/services/dialog.service";
import { Router } from "@angular/router";
import { ProfileService } from "../../services/profile.service";
import { AllTaskKey, Task } from "src/app/interfaces/workflow";
import { WorkflowService } from "src/app/services/workflow.service";
import { SupplierHeaderService } from "../../services/header.service";
import { DictionaryService } from "src/app/services/dictionary.service";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";
import { ProcessNames, SetupProcess } from "src/app/interfaces/workflow";
import { LoadingService } from "../../../../services/loading.service";
import { environment } from "../../../../../environments/environment";
import { getTSMCountryServedQuery } from "../../../../shared/shared";

enum RoleCode {
  supplierAdmin = "supplierAdmin",
  normalUser = "normalUser",
}

const verifySupplierProfileContent = {
  en: {
    title: 'Warning message',
    message: 'Please validate your profile on the True Supplier Marketplace site. Click ‘Ok’ to proceed.'
  },
  it: {
    title: 'Attenzione',
    message: 'Convalida il tuo profilo su True Supplier Marketplace. Fa\' clic su "Ok" per procedere.'
  },
  es: {
    title: 'Mensaje de advertencia',
    message: 'Por favor, valide su perfil en el sitio web de True Supplier Marketplace. Haga clic en "Aceptar" para continuar.'
  },
  pt: {
    title: 'Mensagem de advertência',
    message: 'Por favor, valide o seu perfil no site True Supplier Marketplace. Clique em \'Ok\' para prosseguir.'
  },
  fr: {
    title: 'Message d\'avertissement',
    message: 'Veuillez valider votre profil sur le site True Supplier Marketplace. Cliquez sur "Ok" pour continuer.'
  },
  cn: {
    title: '警告信息',
    message: '请在True Supplier Marketplace网站上验证您的资料。点击\'好的\'继续。'
  },
  jp: {
    title: '警告',
    message: 'True Supplier Marketplaceサイトでのプロフィールを確認してください。「OK」をクリックして次に進みます。'
  },
}
const redirectSupplierProfileContent = {
  en: {
    title: 'Warning message',
    message: 'User redirect to the TSM Application. Click ‘Ok’ to proceed.'
  },
}

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.sass"],
})
export class LayoutComponent implements OnInit {
  multipleSupplierData: any;
  companyCode: any = [];
  privacyStatement = privacyStatementState;
  userTasks: Array<Task> = []; // current login user's active task
  accepting = false;
  isTSM = false;
  tsmId: string;

  constructor(
    private supplierService: SupplierService,
    private authService: AuthService,
    private dialogService: DialogService,
    private dictionaryService: DictionaryService,
    private router: Router,
    private profileService: ProfileService,
    private workflowService: WorkflowService,
    private supplierHeaderService: SupplierHeaderService,
    private languageService: LanguageService,
    private loadingService: LoadingService
  ) { }

  public async popupRedirectTSMSupplierProfile() {
    const language = localStorage.getItem("language") || 'en';
    const tsmId = this.authService.passport.supplierList[0].TsmId;
    const { title, message } = redirectSupplierProfileContent[language];
    return this.dialogService.dialog(
      message,
      ButtonCommands.Ok,
      DialogType.warning,
      title,
      {
        multipleLanguage: true,
        switchLanguageCallback: this.popupRedirectTSMSupplierProfile.bind(this),
      }
    ).then(() => {
      const env = sessionStorage.getItem("tsmEnv");
      const tsmUrl =
        env === "dev"
          ? environment.relationshipdev_url
          : env === "perf"
            ? environment.relationshipperf_url
            : env === "stage"
              ? environment.relationshipstage_url
              : env === "test"
                ? environment.relationshiptest_url
                : env === "hotfix"
                  ? environment.relationshiphotfix_url
                  : environment.relationship_url;
      const redirectUrl =
        tsmUrl +
        tsmId;
      console.debug(`Jump to TSM url: ${redirectUrl}`);
      window.location.href = redirectUrl;
    });
  }

  public async popupVerifyTSMSupplierProfile() {
    const language = localStorage.getItem("language") || 'en';
    const { title, message } = verifySupplierProfileContent[language];
    return this.dialogService.dialog(
      message,
      ButtonCommands.Ok,
      DialogType.warning,
      title,
      {
        multipleLanguage: true,
        switchLanguageCallback: this.popupVerifyTSMSupplierProfile.bind(this),
      }
    ).then(() => {
      const env = sessionStorage.getItem("tsmEnv");
      const tsmUrl =
        env === "dev"
          ? environment.relationshipdev_url
          : env === "perf"
            ? environment.relationshipperf_url
            : env === "hotfix"
              ? environment.relationshiphotfix_url
              : env === "stage"
                ? environment.relationshipstage_url
                : env === "test"
                  ? environment.relationshiptest_url
                  : environment.relationship_url;
      const countryServed = getTSMCountryServedQuery(
        this.profileService.supplierModel.Mapping.setUpCountry,
      );
      const redirectUrl =
        tsmUrl +
        this.tsmId +
        "?countryServed=" +
        countryServed +
        "&login_hint=" +
        this.authService.passport.supplier.Mapping.supplierEmail;
      console.debug(`Jump to TSM url: ${redirectUrl}`);
      window.location.href = redirectUrl;
    });
  }
  async ngOnInit() {
    this.companyCode = await this.dictionaryService.getDictionary(
      "CompanyCode"
    );
    if(!sessionStorage.getItem('tsmcross')) {
      this.popupRedirectTSMSupplierProfile();
    }
    if (
      this.authService.passport.supplierList.length === 1 &&
      this.authService.passport.supplierList[0].TsmId
    ) {
      this.loadingService.showLoading();
      const supplierData = this.authService.passport.supplierList[0];
      const { supplierCode, TsmId: tsmId } = supplierData;
      this.tsmId = tsmId;
      const result: any =
        await this.supplierService.verifyTSMSupplierProfileStatus(
          supplierCode,
          tsmId
        );
      this.loadingService.hideLoading();
      if (!result.data) {
        this.popupVerifyTSMSupplierProfile();
        return false;
      }
    }

    const languageChangeClicked = localStorage.getItem("languageChangeClicked");
    if (languageChangeClicked) {
      this.multipleSupplierData = [];
      localStorage.setItem("languageChangeClicked", "");
      const currentSupplierCode = localStorage.getItem("currentSupplierCode");
      const supplier = this.authService.passport.supplierList.find(
        (s) => s.supplierCode == currentSupplierCode
      );
      this.languageService.setValue({ changelanguagevalue: true });
      supplier.defaults = 'checked'
      this.checkSupplier(supplier);
    } else {
      this.multipleSupplierData = this.authService.passport.supplierList;
    }
    if (
      this.authService.passport.supplierList[0].TsmId &&
      this.authService.passport.supplierList.length === 1
    ) {
      this.isTSM = true;
      const supplier = this.authService.passport.supplierList[0]
      if (
        (supplier.geographicalUnit === "20" &&
          supplier.setUpCountry.length === 1 &&
          supplier.setUpCountry[0] === "IT") ||
        (supplier.geographicalUnit === "19" &&
          (supplier.setUpCountry.includes("PT") ||
            supplier.setUpCountry.includes("ES") ||
            supplier.setUpCountry.includes("AD"))) ||
        (supplier.geographicalUnit === "16" &&
          supplier.setUpCountry.includes("FR")) ||
        (supplier.geographicalUnit === "18") ||
        (supplier.geographicalUnit === "22")
      ) {
        this.languageService.setValue({ supplierInfo: supplier, flag: true });
      } else {
        this.languageService.setValue({ flag: false });
      }
      if (
        this.authService.passport.supplier.Mapping.profile.supplierAccepted !==
        "new" &&
        this.authService.passport.supplierMember.PrivacyStatus !== "new" &&
        this.authService.passport.supplier.Mapping.profile.supplierAccepted !==
        "accept" &&
        this.authService.passport.supplierMember.PrivacyStatus !== "accept" &&
        this.authService.passport.supplier.Mapping.TsmId &&
        !this.profileService.checkTaskIsComplete(
          ProcessNames.generic_supplierSetup,
          SetupProcess.profile_draft
        ) && (this.profileService.current_step === 1)
      ) {
        this.router.navigate([
          `/supplier/profile/${this.authService.passport.supplier.Mapping.supplierCode}/step1`,
        ]);
      }
    }
  }

  @HostListener("document: click", ["$event"])
  documentClick(evt) {
    if (this.privacyStatement.linkType === "footerLink") {
      this.privacyStatement.privacyStatement = true;
    }
  }

  async privacyaccept(flag) {
    if (flag) {
      // upload supplierAccepted status
      this.authService.passport.supplierMember.PrivacyStatus = "accept";
      this.authService.passport.supplier.Mapping.profile.supplierAccepted =
        "accept";
      this.authService.passport.supplierMember.Privacy = "accept";
      if (
        this.authService.passport.supplierMember.RoleCode ===
        RoleCode.supplierAdmin
      ) {
        if (!this.accepting) {
          this.accepting = true;
          const supplierCode =
            this.authService.passport.supplier.Mapping.supplierCode;
          // workflow execute supplier accept task
          const supplierRole = "supplier";
          const task = await this.getUserTask(supplierRole, supplierCode);
          const Email = this.authService.passport.supplierMember.Email;
          await this.supplierService.supplierAccept(supplierCode, task, Email);
          await this.supplierService.supplierNormalUserAccept(
            Email,
            supplierCode
          );
          this.privacyStatement.privacyStatement = false;
          this.privacyStatement.isDisplayAccept = true;
          await this.profileService.loadUserTasks();
          this.accepting = false;
          this.supplierHeaderService.getIsCompletedAndIsSSTRejectOrQCApprove();
        }
      } else if (
        this.authService.passport.supplierMember.RoleCode ===
        RoleCode.normalUser
      ) {
        if (!this.accepting) {
          const supplierCode =
            this.authService.passport.supplier.Mapping.supplierCode;
          const Email = this.authService.passport.supplierMember.Email;
          this.accepting = true;
          const supplierRole = "supplier";
          const task = await this.getUserTask(supplierRole, supplierCode);
          await this.supplierService.supplierAccept(supplierCode, task, Email);
          await this.supplierService
            .supplierNormalUserAccept(Email, supplierCode)
            .then((d) => {
              this.privacyStatement.privacyStatement = false;
              this.privacyStatement.isDisplayAccept = true;
              this.accepting = false;
            });
        }
      }
    } else {
      this.privacyStatement.privacyStatement = false;
      this.privacyStatement.linkType = "";
    }
  }

  async getUserTask(groupNm: string, supplierCode: string): Promise<Task> {
    this.userTasks = await this.workflowService.getUserTasksByGroup(
      groupNm,
      supplierCode
    );
    return this.workflowService.getTaskByTaskKey(
      AllTaskKey.supplier_accepted,
      this.userTasks
    );
  }

  termclose() {
    this.privacyStatement.termOfUse = false;
  }

  // Convert code to text
  transformCompanyCode(item: string) {
    const companyCodeC = eval(item);
    let companyText = "";
    const companyTextArray: string[] = [];
    companyCodeC.forEach((v, i) => {
      this.companyCode.forEach((d: any) => {
        if (d.Code === v) {
          companyText += d.Text;
          if (i < companyCodeC.length - 1) {
            companyText += ",";
          }
        }
      });
    });
    companyTextArray.push(companyText);
    return companyTextArray;
  }

  // Select a supplier in list
  async checkSupplier(supplier: any) {
    localStorage.setItem("currentSupplierCode", supplier.supplierCode);
    // Language switching is allowed only in Italian conditions
    if (
      (supplier.geographicalUnit === "20" &&
        supplier.setUpCountry.length === 1 &&
        supplier.setUpCountry[0] === "IT") ||
      (supplier.geographicalUnit === "19" &&
        (supplier.setUpCountry.includes("PT") ||
          supplier.setUpCountry.includes("ES") ||
          supplier.setUpCountry.includes("AD"))) ||
      (supplier.geographicalUnit === "16" &&
        supplier.setUpCountry.includes("FR")) ||
      (supplier.geographicalUnit === "18") ||
      (supplier.geographicalUnit === "22")
    ) {
      this.languageService.setValue({ supplierInfo: supplier, flag: true });
    } else {
      this.languageService.setValue({ flag: false });
    }

    let data: any;
    if (
      window.location.href
        .split("/")
      [window.location.href.split("/").length - 1].includes("@") &&
      window.location.href.includes("landing")
    ) {
      data = await this.supplierService.initSupplier(
        supplier.supplierCode,
        supplier.supplierEmail
      );

    } else {
      data = await this.supplierService.initSupplier(
        supplier.supplierCode,
        this.authService.passport.profile.email
      );
    }
    if (data.isSuccess) {
      this.authService.passport.supplier = data.data.model;
      this.authService.passport.supplierMember = data.data.member;
      this.authService.initCompleted.emit(true);
      this.privacyStatement.multipleSupplier = false;
        this.profileService.initSupplierProfile();
        if(this.profileService.mode == 'edit' || this.profileService.mode == 'view') {
          this.profileService.loadSupplierProfile();
        }
      if (supplier.TsmId) {
        this.loadingService.showLoading();
        const { supplierCode, TsmId: tsmId } = supplier;
        this.tsmId = tsmId;
        const result: any =
          await this.supplierService.verifyTSMSupplierProfileStatus(
            supplierCode,
            tsmId
          );
        this.loadingService.hideLoading();
        if (!result.data) {
          this.popupVerifyTSMSupplierProfile();
          return false;
        }
      }

      if (
        this.authService.passport.supplier.Mapping.profile.supplierAccepted ===
        "new" ||
        this.authService.passport.supplierMember.PrivacyStatus === "new"
      ) {
        this.privacyStatement.privacyStatement = true;
        this.privacyStatement.isDisplayAccept = false;
      }
      // console.log(data.data.model.Mapping.TsmId);
      if (
        this.authService.passport.supplier.Mapping.profile.supplierAccepted ===
        "accept" ||
        this.authService.passport.supplierMember.PrivacyStatus === "accept"
      ) {
        this.privacyStatement.isDisplayAccept = true;
      }
      if (data.data.model.Mapping.TsmId) {
        this.isTSM = true;
      }
      if (
        this.authService.passport.supplier.Mapping.profile.supplierAccepted !==
        "new" &&
        this.authService.passport.supplierMember.PrivacyStatus !== "new" &&
        this.authService.passport.supplier.Mapping.profile.supplierAccepted !==
        "accept" &&
        this.authService.passport.supplierMember.PrivacyStatus !== "accept" &&
        data.data.model.Mapping.TsmId &&
        !this.profileService.checkTaskIsComplete(
          ProcessNames.generic_supplierSetup,
          SetupProcess.profile_draft
        ) && (this.profileService.current_step === 1)
      ) {
        this.router.navigate([
          `/supplier/profile/${data.data.model.Mapping.supplierCode}/step1`,
        ]);
      }
    } else {
      this.privacyStatement.multipleSupplier = false;
      this.authService.initCompleted.emit(false);
      await this.dialogService.dialog(
        data.error.errorMessage,
        ButtonCommands.Ok
      );
      this.router.navigate(["/home"]);
      return false;
    }
  }
}
