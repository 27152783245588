import { Injectable, EventEmitter, ViewChild } from "@angular/core";
import { SupplierService } from "src/app/services/supplier.service";
import { SupplierInfoType } from "../../../interfaces/supplier";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierModel } from "src/app/interfaces/supplierModel";
import { SupplierProfileModel, initializeSupplierProfileModel } from "src/app/interfaces/SupplierProfileModel";
import {
  SupplierPendingStatus,
  SupplierStatus,
  AP_ReviewStatus,
  RiskAssessmentStatus,
  StatusType,
  SpendCategoryGroup,
  SupplierMappingDTO,
} from "src/app/interfaces/mapping";
import { BuyerService } from "src/app/services/buyer.service";
import { WorkflowService } from "src/app/services/workflow.service";
import {
  ProcessNames,
  SetupProcess,
  MigrateProcess,
  SupplierChangeRequestProcess,
  Task,
  AllTaskKey,
} from "src/app/interfaces/workflow";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
import * as moment from "moment";
import { DataService } from "src/app/services/dataService";
import { Subscription } from "rxjs";
import { isOrganizationEmpty } from "src/app/utils/organizationCheck";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";
import { SupplierFinancial, SupplierGeneral, SupplierOrganization } from "src/app/interfaces/supplierProfile";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { trim } from "src/app/utils";
// import { SupplierModel } from "src/app/interfaces/supplierModel";
type Mode = "view" | "edit" | "create";
 
@Injectable({
  providedIn: "root",
})
export class ProfileService {
  supplierInfoType = SupplierInfoType;
  supplierModel: SupplierModel;
  current_step = 1;
  isGUValEqualsMET = false;
  stepChangeEvent = new EventEmitter();
  isViewSupplier = false;
  message:string;
  subscription: Subscription;
  isNewVersion: boolean = true
  mode: Mode = "view";
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;

  supplierProfileModel: SupplierProfileModel;
  userTasks: Array<Task> = []; // current login user's active task
  commonService: any;
  get profile_step() {
    return this.supplierModel.Mapping.profile.step;
  }
  set profile_step(step) {
    this.supplierModel.Mapping.profile.step = step;
  }
 
  get supplierCode(): string {
    return this.authService.passport.supplier.Mapping.supplierCode;
  }
 
  get isView(): boolean {
    if (this.mode === "view") {
      return true;
    } else {
      return false;
    }
  }
  get spendCategoryGroupValue(): boolean {
    if (this.supplierModel.RequestForm.RequestorDetailsInfo.IndependentContractor == "Y") {
      SpendCategoryGroup['5002'] = false
      SpendCategoryGroup['5021'] = false
    } else {
      SpendCategoryGroup['5002'] = true
      SpendCategoryGroup['5021'] = true
    }
    return SpendCategoryGroup[
      this.supplierModel.RequestForm.RequestorDetailsInfo.SpendCategoryGroup
    ];
  }
 
  get isBIApplicable(): boolean {
    if (this.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1 === "10") {
      return false;
    } else {
      return true;
    }
  }
  public get headerTitle() {
    if (this.mode === "create") {
      return this.languageService.getValue(
        "static.supplier.header.header_title_create"
      );
    }
    if (this.mode === "view") {
      return this.languageService.getValue(
        "static.supplier.header.header_title_view"
      );
    }
    if (this.mode === "edit") {
      return this.languageService.getValue(
        "static.supplier.header.header_title_edit"
      );
    }
  }
 
  constructor(
    private router: Router,
    private authService: AuthService,
    private supplierService: SupplierService,
    private buyerService: BuyerService,
    private workflowService: WorkflowService,
    private languageService: LanguageService,
    private dataService: DataService,
    private launchDarklyService: LaunchDarklyService
  ) {  
    this.launchDarklyService.ldChange.subscribe((any) => {
      this.refreshToggle();
    });
  }

  refreshToggle(): void {
    console.log(this.launchDarklyService.getToggle(
      LaunchDarklyService.LD_ISNEWVERSION
    ), '... isNewVersion');
    this.isNewVersion =
      this.launchDarklyService.getToggle(
        LaunchDarklyService.LD_ISNEWVERSION
      ) === "true";
  }
 
  // update current step
  updateStep(step) {
    // this.supplierProfile.Step = step
  }
 
  backTo() {
    if (this.current_step > 1) {
      if (this.current_step == 5 && this.supplierModel.Mapping.TsmId) {
        this.router.navigate([
          `supplier/profile/${this.supplierCode}/step${this.current_step - 2}`,
        ]);
      } else {
        this.router.navigate([
          `supplier/profile/${this.supplierCode}/step${this.current_step - 1}`,
        ]);
      }
    } else {
      this.router.navigate(["supplier/landing"]);
    }
  }
 
  // service
  initSupplierProfile() {
    this.supplierModel = this.authService.passport.supplier;
  }
 
  async loadSupplierProfile() {
    let data: any;
    if (this.isNewVersion) {
        data = await this.supplierService.queryNewSupplierProfile(
          this.supplierCode
        );
      }
       else {
      data = await this.supplierService.querySupplierProfile(
        this.supplierCode
      );
    }
    if (data.isSuccess) {
      this.supplierModel = data.data;
      this.dataService.currentMessage.subscribe(message => this.message == message);
      if(isOrganizationEmpty(data.data.SupplierProfile.SupplierOrganization)){
        this.dataService.changeMessage(data.data.Mapping.taxIdsFromTsm);
      }else {
        this.dataService.changeMessage(data.data.SupplierProfile.SupplierOrganization);
      }
      if (
        this.checkTaskIsComplete(
          ProcessNames.generic_supplierSetup,
          SetupProcess.profile_draft
        )
      ) {
        this.supplierModel.Mapping.profile.isUpdated = true;
      }
    }
  }
  async loadRequestForm() {
    let data: any;
    if(this.isNewVersion) {
        data = await this.buyerService.queryForNewRequestForm(
          this.supplierCode)
    } else {
      data = await this.buyerService.queryRequestForm(
        this.supplierCode
      );
    }

    if (data.isSuccess) {
      this.supplierModel.RequestForm = data.data.RequestForm;
    }
  }
  
  async checkField(businessRegistrationNb: string) {
    const data: any = await this.supplierService.checkField(
      this.supplierCode,
      businessRegistrationNb
    );
    if (data.isSuccess) {
      return data.data;
    }
  }
  
  // workflow save form
  createSupplierProfileModel() {
    this.supplierProfileModel = initializeSupplierProfileModel();
  }

  // request form: step 1 ~ 3 page save function
  async saveDraft() {
    this.supplierModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    if(!isOrganizationEmpty(this.supplierModel.SupplierProfile.SupplierOrganization)){
      this.dataService.currentMessage.subscribe(message => this.message == message);
      let supOrg:any = this.supplierModel.SupplierProfile.SupplierOrganization
      this.dataService.changeMessage(supOrg);
    }
    await this.supplierService.saveProfileFormDraft(this.supplierModel);
  }
 
  async saveOrganizationDraft() {
    this.supplierProfileModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    if(!isOrganizationEmpty(this.supplierModel.SupplierProfile.SupplierOrganization)){
      this.dataService.currentMessage.subscribe(message => this.message == message);
      let supOrg:any = this.supplierModel.SupplierProfile.SupplierOrganization
      this.dataService.changeMessage(supOrg);
    }
    await this.supplierService.saveOrganizationProfileFormDraft(this.supplierProfileModel);
  }
  
  async saveDraftForFinancial() {
    this.supplierProfileModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    if(!isOrganizationEmpty(this.supplierModel.SupplierProfile.SupplierOrganization)){
      this.dataService.currentMessage.subscribe(message => this.message == message);
      let supOrg:any = this.supplierModel.SupplierProfile.SupplierOrganization
      this.dataService.changeMessage(supOrg);
    }
    await this.supplierService.saveFinancialProfileFormDraft(this.supplierProfileModel);
  }

  async saveGeneralDraft() {
    console.log('before save genaral')
    this.supplierProfileModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    await this.supplierService.saveGeneralProfileFormDraft(this.supplierProfileModel);
    console.log('After save genaral')
  }
 
  // request form: step 4 page submit and execute supplier stepup process's request_draft task
  async submitDraftForExisting() {
    this.supplierModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    const data: any = await this.supplierService.submitProfileFormDraftForExsting(
      this.supplierModel
    );
    if (data.isSuccess) {
      this.supplierModel = data.data;
    }
  }
  async submitDraft() {
    this.supplierProfileModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    const data: any = await this.supplierService.submitProfileFormDraft(this.supplierProfileModel);
  }
 
  async submitMigrateDraft() {
    this.supplierModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    await this.supplierService.submitMigrateProfileFormDraft(
      this.supplierModel
    );
  }
 
  // all sst reject to buyer task
  async resubmitForm() {
    this.supplierModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    await this.supplierService.resubmitProfileForm(this.supplierModel);
  }
  
  async newresubmitForm() {
    this.supplierProfileModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    await this.supplierService.newresubmitProfileForm(this.supplierProfileModel);
  }
 
  // all buyer change request process: save request form
  async changeRequestForm() {
    this.supplierModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    const data: any = await this.supplierService.changeProfileForm(
      this.supplierModel
    );
    if (data.isSuccess) {
      this.supplierModel = data.data;
    }
  }

  async newchangeRequestForm() {
    this.supplierProfileModel.Mapping.requestor.updatedBy =
      this.authService.passport.profile.email;
    const data: any = await this.supplierService.newchangeProfileForm(
      this.supplierProfileModel
    );
  }
 
  // check resubmit button is enable
  checkResubmit() {
    // read only
    if (this.isView) {
      return false;
      // migrating process in progress:  migrate profile draft
    } else if (
      this.getUserTaskByKey(
        ProcessNames.generic_supplierMigrateSetup,
        MigrateProcess.profile_draft
      )
    ) {
      return false;
      // reject to supplier
    } else if (this.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)) {
      return true;
      // supplier change request
    } else if (this.supplierModel.Mapping.status === SupplierStatus.onboarded) {
      return true;
    } else {
      return false;
    }
  }
 
  // // get task by group and user
  // private async getTaskByGroup(groupNm: string) {
  //   const data = await this.workflowService.getTaskByGroup(groupNm, this.supplierModel.Mapping.supplierCode)
  //   if (data.isSuccess) {
  //     this.userTasks = data.data
  //   }
  // }
 
  // load all tasks
  async loadUserTasks() {
    const supplierRole = "supplier";
    this.userTasks = await this.workflowService.loadUserTasks(
      supplierRole,
      this.supplierModel.Mapping.supplierCode
    );
  }
 
  // get active task by processKey and taskKey
  getUserTaskByKey(processKey: string, taskKey: string): Task {
    return this.workflowService.getTaskByKey(
      processKey,
      taskKey,
      this.userTasks
    );
  }
 
  // get active task by task key
  getUserTaskByTaskKey(taskKey: string): Task {
    return this.workflowService.getTaskByTaskKey(taskKey, this.userTasks);
  }
 
  // Does not include AP sub process
  checkTaskIsComplete(processKey: string, taskKey: string) {
    return this.workflowService.checkTaskIsComplete(
      processKey,
      taskKey,
      this.supplierModel.Mapping.historyTasks
    );
  }
 
  // AP sub process
  // check ap approve
  checkAPTaskIsComplete(processKey: string, taskKey: string) {
    return this.workflowService.checkAPTaskIsComplete(
      processKey,
      taskKey,
      this.supplierModel.Mapping.historyTasks
    );
  }
 
  // check ap reject
  checkAPTaskIsReject(processKey: string, taskKey: string) {
    return this.workflowService.checkAPTaskIsReject(
      processKey,
      taskKey,
      this.supplierModel.Mapping.historyTasks
    );
  }
 
  checkSupplierIsCompleteForTsm() {
    // request draft
    if (
      this.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.profile_draft
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
 
  // get execute task
  getExecuteTask(
    processKey: string,
    taskKey: string,
    variables: any = {}
  ): SupplierModel {
    this.supplierModel.Mapping.executeTask =
      this.workflowService.getExecuteTask(processKey, taskKey, variables);
    return this.supplierModel;
  }
 
  getResubmitTask(): SupplierModel {
    this.supplierModel.Mapping.executeTask = this.getUserTaskByTaskKey(
      AllTaskKey.SST_rejectToSupplier
    );
    return this.supplierModel;
  }
  async getProcessInstanceByBusinessKey(supplierCode: string) {
    const result = await this.workflowService.getProcessInstanceByBusinessKey(
      supplierCode
    );
    if (result.isSuccess) {
      return result.data;
    }
  }
}